import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Listing from "../components/Listing"
import ListingCard from "../components/ListingCard"
import Seo from "../components/Seo"
import { shuffleOrder } from "../constants/helpers"

export default function Index({ data }) {
  const dataIndex = data?.index || {}

  // Titles
  const highlightTitleRu =
    dataIndex.highlightTitle && dataIndex.highlightTitle.ru
      ? dataIndex.highlightTitle.ru
      : "Актуально"
  const highlightTitleFi =
    dataIndex.highlightTitle && dataIndex.highlightTitle.fi
      ? dataIndex.highlightTitle.fi
      : "Ajankohtainen"
  const sectionsTitleRu =
    dataIndex.sectionsTitle && dataIndex.sectionsTitle.ru
      ? dataIndex.sectionsTitle.ru
      : "Рубрики"
  const sectionsTitleFi =
    dataIndex.sectionsTitle && dataIndex.sectionsTitle.fi
      ? dataIndex.sectionsTitle.fi
      : "Luokat"
  const articlesTitleRu =
    dataIndex.articlesTitle && dataIndex.articlesTitle.ru
      ? dataIndex.articlesTitle.ru
      : "Статьи"
  const articlesTitleFi =
    dataIndex.articlesTitle && dataIndex.articlesTitle.fi
      ? dataIndex.articlesTitle.fi
      : "Artikkelit"
  // Limits
  const sectionsLimit = dataIndex.sectionsCount?.desktop
    ? dataIndex.sectionsCount.desktop
    : 6
  // const sectionsLimitMobile = dataIndex.sectionsCount?.mobile ? dataIndex.sectionsCount.mobile : 3
  const articlesLimit = dataIndex.articlesCount?.desktop
    ? dataIndex.articlesCount.desktop
    : 6
  const articlesLimitMobile = dataIndex.articlesCount?.mobile
    ? dataIndex.articlesCount.mobile
    : 6
  // Highlight
  const highlightData = dataIndex.highlightSelect
    ? dataIndex.highlightSelect
    : null
  // Sections & Articles
  const sectionsIds = dataIndex.sectionsSelect
    ? dataIndex.sectionsSelect.map(section => section._id)
    : []
  const sectionsOverrideIds = dataIndex.sectionsOverride
    ? dataIndex.sectionsOverride.map(article => article._id)
    : []
  const articlesOverrideIds = dataIndex.articlesOverride
    ? dataIndex.articlesOverride.map(article => article._id)
    : []
  let sectionsData = dataIndex.sectionsOverride
    ? [...dataIndex.sectionsOverride]
    : []
  let articlesData = dataIndex.articlesOverride
    ? [...dataIndex.articlesOverride]
    : []
  let sectionsFiltered = []
  let articlesFiltered = []
  for (const edge of data.articles.edges) {
    if (
      edge.node.section &&
      edge.node.section._id === "7bfdd8b3-3f97-44f1-8f11-8ef684a989a6" &&
      !articlesOverrideIds.includes(edge.node._id)
    ) {
      articlesFiltered.push(edge.node)
    } else if (
      edge.node.section &&
      sectionsIds.includes(edge.node.section._id) &&
      !sectionsOverrideIds.includes(edge.node._id)
    ) {
      sectionsFiltered.push(edge.node)
    }
  }
  sectionsFiltered = shuffleOrder(sectionsFiltered)
  articlesFiltered = shuffleOrder(articlesFiltered)
  sectionsData = [...sectionsData, ...sectionsFiltered]
  articlesData = [...articlesData, ...articlesFiltered]

  return (
    <Layout>
      <Seo seoData={dataIndex} />
      {highlightData && (
        <section className="highlight-section">
          <p className="section-caption">
            <span className="russian" lang="ru">
              {highlightTitleRu}
            </span>
            <span className="finnish" lang="fi">
              {highlightTitleFi}
            </span>
          </p>
          <ListingCard data={highlightData} mode="featured" />
        </section>
      )}
      <section className="articles-section">
        <p className="section-caption">
          <span className="russian" lang="ru">
            {articlesTitleRu}
          </span>
          <span className="finnish" lang="fi">
            {articlesTitleFi}
          </span>
        </p>
        <Listing
          data={articlesData}
          limit={[articlesLimit, articlesLimitMobile]}
        />
      </section>
      <section className="sections-section">
        <p className="section-caption">
          <span className="russian" lang="ru">
            {sectionsTitleRu}
          </span>
          <span className="finnish" lang="fi">
            {sectionsTitleFi}
          </span>
        </p>
        <Listing
          data={sectionsData}
          pagesPerLoad={sectionsLimit}
          mode="sections"
        />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query GetIndex {
    index: sanityIndex(_id: { in: ["index", "drafts.index"] }) {
      _id
      _type
      highlightTitle {
        fi
        ru
      }
      highlightSelect {
        ... on SanityArticle {
          _id
          _type
          date
          title {
            fi
            ru
          }
          description {
            fi
            ru
          }
          section {
            _id
            slug {
              current
            }
            title {
              fi
              ru
            }
          }
          slug {
            current
          }
          author {
            name {
              fi
              ru
            }
          }
          cover {
            asset {
              _id
            }
          }
          thumb {
            asset {
              _id
            }
          }
        }
        ... on SanityIssue {
          _id
          _type
          title {
            fi
            ru
          }
          slug {
            current
          }
          number
          link
          description {
            fi
            ru
          }
          covers {
            format
            image {
              asset {
                _id
              }
            }
          }
        }
        ... on SanityTopic {
          _id
          _type
          title {
            fi
            ru
          }
          slug {
            current
          }
          description {
            fi
            ru
          }
          covers {
            format
            image {
              asset {
                _id
              }
            }
          }
        }
        ... on SanityPage {
          _id
          _type
          title {
            fi
            ru
          }
          description {
            fi
            ru
          }
          slug {
            current
          }
          thumb {
            asset {
              _id
            }
          }
        }
      }
      sectionsTitle {
        fi
        ru
      }
      sectionsSelect {
        _id
      }
      sectionsOverride {
        _id
        _type
        date
        title {
          fi
          ru
        }
        description {
          fi
          ru
        }
        section {
          _id
          slug {
            current
          }
          title {
            fi
            ru
          }
        }
        slug {
          current
        }
        author {
          name {
            fi
            ru
          }
        }
        cover {
          asset {
            _id
          }
        }
        thumb {
          asset {
            _id
          }
        }
      }
      sectionsCount {
        desktop
        mobile
      }
      articlesTitle {
        fi
        ru
      }
      articlesOverride {
        _id
        _type
        date
        title {
          fi
          ru
        }
        description {
          fi
          ru
        }
        section {
          _id
          slug {
            current
          }
          title {
            fi
            ru
          }
        }
        slug {
          current
        }
        author {
          name {
            fi
            ru
          }
        }
        cover {
          asset {
            _id
          }
        }
        thumb {
          asset {
            _id
          }
        }
      }
      articlesCount {
        desktop
        mobile
      }
      seo {
        metaTitle
        metaDescription
        metaKeywords
        sharedImage {
          asset {
            _id
          }
        }
        preventIndexing
      }
    }
    articles: allSanityArticle(sort: { fields: date, order: DESC }) {
      edges {
        node {
          _id
          _type
          date
          slug {
            current
          }
          cover {
            asset {
              _id
            }
          }
          thumb {
            asset {
              _id
            }
          }
          title {
            fi
            ru
          }
          description {
            fi
            ru
          }
          author {
            name {
              ru
              fi
            }
          }
          section {
            _id
            title {
              ru
              fi
            }
            slug {
              current
            }
          }
        }
      }
    }
  }
`
